import React, { Component } from 'react';

class YearSelect extends Component {

    state = { 
        dataLoaded: false,
        years: []
    }

    componentDidMount(){
        // Fetch the years
        fetch(`${process.env.REACT_APP_API_URL}/vehicleInfo/getYears`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {

                        this.setState({
                            ...this.state,
                            dataLoaded: true,
                            years: result.years
                        })
                    } else if (result["success"] === false) { 
                        console.log("Error fetching vehicle years.")
                    } else {
                        console.log("Error fetching vehicle years.")
                    }
                }).catch(error => {
                    console.log("Error fetching vehicle years.")                                      
                });  
    }

    render() { 
        return ( 
            this.state.dataLoaded ? 
                <select value={this.props.defaultValue} className="form-control" onChange={event => this.props.handleChange(this.props.questionId, event.target.value, false, true, 'year')}>
                    <option></option>
                    {this.state.years.map(year => {
                        return <option value={year}>{year}</option>
                    })}
                </select>
            :
                <select className="form-control">
                    <option> - </option>
                </select>
        );
    }
}
 
export default YearSelect;