import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class Loading extends Component {
    state = {  }
    render() { 

        return ( 
            <div className="alert alert-light shadow-sm mt-5" style={{maxWidth:'180px', marginTop:'1rem', marginLeft:'auto', marginRight:'auto'}}>
                <div className="text-info d-flex">
                    <div className="d-flex align-items-center mr-3"><FontAwesomeIcon icon={faSpinner} size="1x" spin /></div>
                    <div className="d-flex align-items-center">LOADING</div>
                </div>
            </div>
        );
    }
}
 
export default Loading;