import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mapSurveyResponses from '../../../../helpers/mapSurveyQuestions';

class CallToAction extends Component {

    state = { 
        submitting: false
    }

    saveAndContinue = () => {

        this.setState({submitting:true})

        const nextStep = this.props.nextStep

        if (!this.props.lastQuestion){
            setTimeout(function(){
                nextStep(false)
            }, 150)
        } else {
            setTimeout(function(){
                nextStep(true)
            }, 150)
        }
    }

    render() { 
        return ( 
            <div className="mt-5">
                {this.state.submitting ?
                    <button className="btn btn-primary btn-lg" disabled><FontAwesomeIcon icon="spinner" spin /> {this.props.callToAction}</button>
                :
                    !this.props.getNextButton.type || this.props.getNextButton.type === 'submitDisabled' ?
                        <button className="btn btn-primary btn-lg" disabled>{this.props.callToAction}</button>
                    :
                        this.props.redirect ?
                            // This means there is a redirect in place.
                            <a className="btn btn-primary btn-lg" onClick={() =>this.saveAndContinue()} href={`${mapSurveyResponses(this.props.redirect, this.props.fullSurvey)}`} target="_blank">{this.props.callToAction !== '' ? this.props.callToAction : 'Go Here!'}</a>
                        :
                            <button className="btn btn-primary btn-lg" onClick={()=>this.saveAndContinue()}>{this.props.callToAction}</button>
                }                
            </div>
        );
    }

}
 
export default CallToAction;