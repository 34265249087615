import React, { useEffect } from 'react';

const PushnamiUpdate = ({firstName='', lastName='', email=''}) => {
    // This script is to update Pushnami with some of the user information.    
    useEffect(()=>{
        if (typeof window !== "undefined" && window.Pushnami){            
            window.Pushnami.update({
                "fname": firstName,
                "lname": lastName,
                "email": email
            })
        }
    }, [firstName, lastName, email]);

    return null;

}
 
export default PushnamiUpdate;