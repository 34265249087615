const countries = {
  countries: [
    {
      "name": "Andorra",
      "code": "AD",
      "code_3": "AND",
      "codenum": 20
    },
    {
      "name": "Afghanistan",
      "code": "AF",
      "code_3": "AFG",
      "codenum": 4
    },
    {
      "name": "Antigua and Barbuda",
      "code": "AG",
      "code_3": "ATG",
      "codenum": 28
    },
    {
      "name": "Anguilla",
      "code": "AI",
      "code_3": "AIA",
      "codenum": 660
    },
    {
      "name": "Albania",
      "code": "AL",
      "code_3": "ALB",
      "codenum": 8
    },
    {
      "name": "Armenia",
      "code": "AM",
      "code_3": "ARM",
      "codenum": 51
    },
    {
      "name": "Angola",
      "code": "AO",
      "code_3": "AGO",
      "codenum": 24
    },
    {
      "name": "Antarctica",
      "code": "AQ",
      "code_3": "ATA",
      "codenum": 10
    },
    {
      "name": "Argentina",
      "code": "AR",
      "code_3": "ARG",
      "codenum": 32
    },
    {
      "name": "American Samoa",
      "code": "AS",
      "code_3": "ASM",
      "codenum": 16
    },
    {
      "name": "Austria",
      "code": "AT",
      "code_3": "AUT",
      "codenum": 40
    },
    {
      "name": "Australia",
      "code": "AU",
      "code_3": "AUS",
      "codenum": 36
    },
    {
      "name": "Aruba",
      "code": "AW",
      "code_3": "ABW",
      "codenum": 533
    },
    {
      "name": "Aland Islands",
      "code": "AX",
      "code_3": "ALA",
      "codenum": 248
    },
    {
      "name": "Azerbaijan",
      "code": "AZ",
      "code_3": "AZE",
      "codenum": 31
    },
    {
      "name": "Bosnia and Herzegovina",
      "code": "BA",
      "code_3": "BIH",
      "codenum": 70
    },
    {
      "name": "Barbados",
      "code": "BB",
      "code_3": "BRB",
      "codenum": 52
    },
    {
      "name": "Bangladesh",
      "code": "BD",
      "code_3": "BGD",
      "codenum": 50
    },
    {
      "name": "Belgium",
      "code": "BE",
      "code_3": "BEL",
      "codenum": 56
    },
    {
      "name": "Burkina Faso",
      "code": "BF",
      "code_3": "BFA",
      "codenum": 854
    },
    {
      "name": "Bulgaria",
      "code": "BG",
      "code_3": "BGR",
      "codenum": 100
    },
    {
      "name": "Bahrain",
      "code": "BH",
      "code_3": "BHR",
      "codenum": 48
    },
    {
      "name": "Burundi",
      "code": "BI",
      "code_3": "BDI",
      "codenum": 108
    },
    {
      "name": "Benin",
      "code": "BJ",
      "code_3": "BEN",
      "codenum": 204
    },
    {
      "name": "Saint Barthelemy",
      "code": "BL",
      "code_3": "BLM",
      "codenum": 652
    },
    {
      "name": "Bermuda",
      "code": "BM",
      "code_3": "BMU",
      "codenum": 60
    },
    {
      "name": "Brunei Darussalam",
      "code": "BN",
      "code_3": "BRN",
      "codenum": 96
    },
    {
      "name": "Bolivia, Plurinational State of",
      "code": "BO",
      "code_3": "BOL",
      "codenum": 68
    },
    {
      "name": "Bonaire, Sint Eustatius and Saba",
      "code": "BQ",
      "code_3": "BES",
      "codenum": 535
    },
    {
      "name": "Brazil",
      "code": "BR",
      "code_3": "BRA",
      "codenum": 76
    },
    {
      "name": "Bahamas",
      "code": "BS",
      "code_3": "BHS",
      "codenum": 44
    },
    {
      "name": "Bhutan",
      "code": "BT",
      "code_3": "BTN",
      "codenum": 64
    },
    {
      "name": "Bouvet Island",
      "code": "BV",
      "code_3": "BVT",
      "codenum": 74
    },
    {
      "name": "Botswana",
      "code": "BW",
      "code_3": "BWA",
      "codenum": 72
    },
    {
      "name": "Belarus",
      "code": "BY",
      "code_3": "BLR",
      "codenum": 112
    },
    {
      "name": "Belize",
      "code": "BZ",
      "code_3": "BLZ",
      "codenum": 84
    },
    {
      "name": "Canada",
      "code": "CA",
      "code_3": "CAN",
      "codenum": 124
    },
    {
      "name": "Cocos (Keeling) Islands",
      "code": "CC",
      "code_3": "CCK",
      "codenum": 166
    },
    {
      "name": "Congo, The Democratic Republic of The",
      "code": "CD",
      "code_3": "COD",
      "codenum": 180
    },
    {
      "name": "Central African Republic",
      "code": "CF",
      "code_3": "CAF",
      "codenum": 140
    },
    {
      "name": "Congo",
      "code": "CG",
      "code_3": "COG",
      "codenum": 178
    },
    {
      "name": "Switzerland",
      "code": "CH",
      "code_3": "CHE",
      "codenum": 756
    },
    {
      "name": "Cote D'ivoire",
      "code": "CI",
      "code_3": "CIV",
      "codenum": 384
    },
    {
      "name": "Cook Islands",
      "code": "CK",
      "code_3": "COK",
      "codenum": 184
    },
    {
      "name": "Chile",
      "code": "CL",
      "code_3": "CHL",
      "codenum": 152
    },
    {
      "name": "Cameroon",
      "code": "CM",
      "code_3": "CMR",
      "codenum": 120
    },
    {
      "name": "China",
      "code": "CN",
      "code_3": "CHN",
      "codenum": 156
    },
    {
      "name": "Colombia",
      "code": "CO",
      "code_3": "COL",
      "codenum": 170
    },
    {
      "name": "Costa Rica",
      "code": "CR",
      "code_3": "CRI",
      "codenum": 188
    },
    {
      "name": "Cuba",
      "code": "CU",
      "code_3": "CUB",
      "codenum": 192
    },
    {
      "name": "Cabo Verde",
      "code": "CV",
      "code_3": "CPV",
      "codenum": 132
    },
    {
      "name": "Curacao",
      "code": "CW",
      "code_3": "CUW",
      "codenum": 531
    },
    {
      "name": "Christmas Island",
      "code": "CX",
      "code_3": "CXR",
      "codenum": 162
    },
    {
      "name": "Cyprus",
      "code": "CY",
      "code_3": "CYP",
      "codenum": 196
    },
    {
      "name": "Czech Republic",
      "code": "CZ",
      "code_3": "CZE",
      "codenum": 203
    },
    {
      "name": "Germany",
      "code": "DE",
      "code_3": "DEU",
      "codenum": 276
    },
    {
      "name": "Djibouti",
      "code": "DJ",
      "code_3": "DJI",
      "codenum": 262
    },
    {
      "name": "Denmark",
      "code": "DK",
      "code_3": "DNK",
      "codenum": 208
    },
    {
      "name": "Dominica",
      "code": "DM",
      "code_3": "DMA",
      "codenum": 212
    },
    {
      "name": "Dominican Republic",
      "code": "DO",
      "code_3": "DOM",
      "codenum": 214
    },
    {
      "name": "Algeria",
      "code": "DZ",
      "code_3": "DZA",
      "codenum": 12
    },
    {
      "name": "Ecuador",
      "code": "EC",
      "code_3": "ECU",
      "codenum": 218
    },
    {
      "name": "Estonia",
      "code": "EE",
      "code_3": "EST",
      "codenum": 233
    },
    {
      "name": "Egypt",
      "code": "EG",
      "code_3": "EGY",
      "codenum": 818
    },
    {
      "name": "Western Sahara",
      "code": "EH",
      "code_3": "ESH",
      "codenum": 732
    },
    {
      "name": "Eritrea",
      "code": "ER",
      "code_3": "ERI",
      "codenum": 232
    },
    {
      "name": "Spain",
      "code": "ES",
      "code_3": "ESP",
      "codenum": 724
    },
    {
      "name": "Ethiopia",
      "code": "ET",
      "code_3": "ETH",
      "codenum": 231
    },
    {
      "name": "Finland",
      "code": "FI",
      "code_3": "FIN",
      "codenum": 246
    },
    {
      "name": "Fiji",
      "code": "FJ",
      "code_3": "FJI",
      "codenum": 242
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "code": "FK",
      "code_3": "FLK",
      "codenum": 238
    },
    {
      "name": "Micronesia, Federated States of",
      "code": "FM",
      "code_3": "FSM",
      "codenum": 583
    },
    {
      "name": "Faroe Islands",
      "code": "FO",
      "code_3": "FRO",
      "codenum": 234
    },
    {
      "name": "France",
      "code": "FR",
      "code_3": "FRA",
      "codenum": 250
    },
    {
      "name": "Gabon",
      "code": "GA",
      "code_3": "GAB",
      "codenum": 266
    },
    {
      "name": "Grenada",
      "code": "GD",
      "code_3": "GRD",
      "codenum": 308
    },
    {
      "name": "Georgia",
      "code": "GE",
      "code_3": "GEO",
      "codenum": 268
    },
    {
      "name": "French Guiana",
      "code": "GF",
      "code_3": "GUF",
      "codenum": 254
    },
    {
      "name": "Guernsey",
      "code": "GG",
      "code_3": "GGY",
      "codenum": 831
    },
    {
      "name": "Ghana",
      "code": "GH",
      "code_3": "GHA",
      "codenum": 288
    },
    {
      "name": "Gibraltar",
      "code": "GI",
      "code_3": "GIB",
      "codenum": 292
    },
    {
      "name": "Greenland",
      "code": "GL",
      "code_3": "GRL",
      "codenum": 304
    },
    {
      "name": "Gambia",
      "code": "GM",
      "code_3": "GMB",
      "codenum": 270
    },
    {
      "name": "Guinea",
      "code": "GN",
      "code_3": "GIN",
      "codenum": 324
    },
    {
      "name": "Guadeloupe",
      "code": "GP",
      "code_3": "GLP",
      "codenum": 312
    },
    {
      "name": "Equatorial Guinea",
      "code": "GQ",
      "code_3": "GNQ",
      "codenum": 226
    },
    {
      "name": "Greece",
      "code": "GR",
      "code_3": "GRC",
      "codenum": 300
    },
    {
      "name": "South Georgia and The South Sandwich Islands",
      "code": "GS",
      "code_3": "SGS",
      "codenum": 239
    },
    {
      "name": "Guatemala",
      "code": "GT",
      "code_3": "GTM",
      "codenum": 320
    },
    {
      "name": "Guam",
      "code": "GU",
      "code_3": "GUM",
      "codenum": 316
    },
    {
      "name": "Guinea-Bissau",
      "code": "GW",
      "code_3": "GNB",
      "codenum": 624
    },
    {
      "name": "Guyana",
      "code": "GY",
      "code_3": "GUY",
      "codenum": 328
    },
    {
      "name": "Hong Kong",
      "code": "HK",
      "code_3": "HKG",
      "codenum": 344
    },
    {
      "name": "Heard Island and Mcdonald Islands",
      "code": "HM",
      "code_3": "HMD",
      "codenum": 334
    },
    {
      "name": "Honduras",
      "code": "HN",
      "code_3": "HND",
      "codenum": 340
    },
    {
      "name": "Croatia",
      "code": "HR",
      "code_3": "HRV",
      "codenum": 191
    },
    {
      "name": "Haiti",
      "code": "HT",
      "code_3": "HTI",
      "codenum": 332
    },
    {
      "name": "Hungary",
      "code": "HU",
      "code_3": "HUN",
      "codenum": 348
    },
    {
      "name": "Indonesia",
      "code": "ID",
      "code_3": "IDN",
      "codenum": 360
    },
    {
      "name": "Ireland",
      "code": "IE",
      "code_3": "IRL",
      "codenum": 372
    },
    {
      "name": "Israel",
      "code": "IL",
      "code_3": "ISR",
      "codenum": 376
    },
    {
      "name": "Isle of Man",
      "code": "IM",
      "code_3": "IMN",
      "codenum": 833
    },
    {
      "name": "India",
      "code": "IN",
      "code_3": "IND",
      "codenum": 356
    },
    {
      "name": "British Indian Ocean Territory",
      "code": "IO",
      "code_3": "IOT",
      "codenum": 86
    },
    {
      "name": "Iraq",
      "code": "IQ",
      "code_3": "IRQ",
      "codenum": 368
    },
    {
      "name": "Iran, Islamic Republic of",
      "code": "IR",
      "code_3": "IRN",
      "codenum": 364
    },
    {
      "name": "Iceland",
      "code": "IS",
      "code_3": "ISL",
      "codenum": 352
    },
    {
      "name": "Italy",
      "code": "IT",
      "code_3": "ITA",
      "codenum": 380
    },
    {
      "name": "Jersey",
      "code": "JE",
      "code_3": "JEY",
      "codenum": 832
    },
    {
      "name": "Jamaica",
      "code": "JM",
      "code_3": "JAM",
      "codenum": 388
    },
    {
      "name": "Jordan",
      "code": "JO",
      "code_3": "JOR",
      "codenum": 400
    },
    {
      "name": "Japan",
      "code": "JP",
      "code_3": "JPN",
      "codenum": 392
    },
    {
      "name": "Kenya",
      "code": "KE",
      "code_3": "KEN",
      "codenum": 404
    },
    {
      "name": "Kyrgyzstan",
      "code": "KG",
      "code_3": "KGZ",
      "codenum": 417
    },
    {
      "name": "Cambodia",
      "code": "KH",
      "code_3": "KHM",
      "codenum": 116
    },
    {
      "name": "Kiribati",
      "code": "KI",
      "code_3": "KIR",
      "codenum": 296
    },
    {
      "name": "Comoros",
      "code": "KM",
      "code_3": "COM",
      "codenum": 174
    },
    {
      "name": "Saint Kitts and Nevis",
      "code": "KN",
      "code_3": "KNA",
      "codenum": 659
    },
    {
      "name": "Korea, Democratic People's Republic of",
      "code": "KP",
      "code_3": "PRK",
      "codenum": 408
    },
    {
      "name": "Korea, Republic of",
      "code": "KR",
      "code_3": "KOR",
      "codenum": 410
    },
    {
      "name": "Kuwait",
      "code": "KW",
      "code_3": "KWT",
      "codenum": 414
    },
    {
      "name": "Cayman Islands",
      "code": "KY",
      "code_3": "CYM",
      "codenum": 136
    },
    {
      "name": "Kazakhstan",
      "code": "KZ",
      "code_3": "KAZ",
      "codenum": 398
    },
    {
      "name": "Lao People's Democratic Republic",
      "code": "LA",
      "code_3": "LAO",
      "codenum": 418
    },
    {
      "name": "Lebanon",
      "code": "LB",
      "code_3": "LBN",
      "codenum": 422
    },
    {
      "name": "Saint Lucia",
      "code": "LC",
      "code_3": "LCA",
      "codenum": 662
    },
    {
      "name": "Liechtenstein",
      "code": "LI",
      "code_3": "LIE",
      "codenum": 438
    },
    {
      "name": "Sri Lanka",
      "code": "LK",
      "code_3": "LKA",
      "codenum": 144
    },
    {
      "name": "Liberia",
      "code": "LR",
      "code_3": "LBR",
      "codenum": 430
    },
    {
      "name": "Lesotho",
      "code": "LS",
      "code_3": "LSO",
      "codenum": 426
    },
    {
      "name": "Lithuania",
      "code": "LT",
      "code_3": "LTU",
      "codenum": 440
    },
    {
      "name": "Luxembourg",
      "code": "LU",
      "code_3": "LUX",
      "codenum": 442
    },
    {
      "name": "Latvia",
      "code": "LV",
      "code_3": "LVA",
      "codenum": 428
    },
    {
      "name": "Libya",
      "code": "LY",
      "code_3": "LBY",
      "codenum": 434
    },
    {
      "name": "Morocco",
      "code": "MA",
      "code_3": "MAR",
      "codenum": 504
    },
    {
      "name": "Monaco",
      "code": "MC",
      "code_3": "MCO",
      "codenum": 492
    },
    {
      "name": "Moldova, Republic of",
      "code": "MD",
      "code_3": "MDA",
      "codenum": 498
    },
    {
      "name": "Montenegro",
      "code": "ME",
      "code_3": "MNE",
      "codenum": 499
    },
    {
      "name": "Saint Martin (French Part)",
      "code": "MF",
      "code_3": "MAF",
      "codenum": 663
    },
    {
      "name": "Madagascar",
      "code": "MG",
      "code_3": "MDG",
      "codenum": 450
    },
    {
      "name": "Marshall Islands",
      "code": "MH",
      "code_3": "MHL",
      "codenum": 584
    },
    {
      "name": "Macedonia, The Former Yugoslav Republic of",
      "code": "MK",
      "code_3": "MKD",
      "codenum": 807
    },
    {
      "name": "Mali",
      "code": "ML",
      "code_3": "MLI",
      "codenum": 466
    },
    {
      "name": "Myanmar",
      "code": "MM",
      "code_3": "MMR",
      "codenum": 104
    },
    {
      "name": "Mongolia",
      "code": "MN",
      "code_3": "MNG",
      "codenum": 496
    },
    {
      "name": "Macao",
      "code": "MO",
      "code_3": "MAC",
      "codenum": 446
    },
    {
      "name": "Northern Mariana Islands",
      "code": "MP",
      "code_3": "MNP",
      "codenum": 580
    },
    {
      "name": "Martinique",
      "code": "MQ",
      "code_3": "MTQ",
      "codenum": 474
    },
    {
      "name": "Mauritania",
      "code": "MR",
      "code_3": "MRT",
      "codenum": 478
    },
    {
      "name": "Montserrat",
      "code": "MS",
      "code_3": "MSR",
      "codenum": 500
    },
    {
      "name": "Malta",
      "code": "MT",
      "code_3": "MLT",
      "codenum": 470
    },
    {
      "name": "Mauritius",
      "code": "MU",
      "code_3": "MUS",
      "codenum": 480
    },
    {
      "name": "Maldives",
      "code": "MV",
      "code_3": "MDV",
      "codenum": 462
    },
    {
      "name": "Malawi",
      "code": "MW",
      "code_3": "MWI",
      "codenum": 454
    },
    {
      "name": "Mexico",
      "code": "MX",
      "code_3": "MEX",
      "codenum": 484
    },
    {
      "name": "Malaysia",
      "code": "MY",
      "code_3": "MYS",
      "codenum": 458
    },
    {
      "name": "Mozambique",
      "code": "MZ",
      "code_3": "MOZ",
      "codenum": 508
    },
    {
      "name": "Namibia",
      "code": "NA",
      "code_3": "NAM",
      "codenum": 516
    },
    {
      "name": "New Caledonia",
      "code": "NC",
      "code_3": "NCL",
      "codenum": 540
    },
    {
      "name": "Niger",
      "code": "NE",
      "code_3": "NER",
      "codenum": 562
    },
    {
      "name": "Norfolk Island",
      "code": "NF",
      "code_3": "NFK",
      "codenum": 574
    },
    {
      "name": "Nigeria",
      "code": "NG",
      "code_3": "NGA",
      "codenum": 566
    },
    {
      "name": "Nicaragua",
      "code": "NI",
      "code_3": "NIC",
      "codenum": 558
    },
    {
      "name": "Netherlands",
      "code": "NL",
      "code_3": "NLD",
      "codenum": 528
    },
    {
      "name": "Norway",
      "code": "NO",
      "code_3": "NOR",
      "codenum": 578
    },
    {
      "name": "Nepal",
      "code": "NP",
      "code_3": "NPL",
      "codenum": 524
    },
    {
      "name": "Nauru",
      "code": "NR",
      "code_3": "NRU",
      "codenum": 520
    },
    {
      "name": "Niue",
      "code": "NU",
      "code_3": "NIU",
      "codenum": 570
    },
    {
      "name": "New Zealand",
      "code": "NZ",
      "code_3": "NZL",
      "codenum": 554
    },
    {
      "name": "Oman",
      "code": "OM",
      "code_3": "OMN",
      "codenum": 512
    },
    {
      "name": "Panama",
      "code": "PA",
      "code_3": "PAN",
      "codenum": 591
    },
    {
      "name": "Peru",
      "code": "PE",
      "code_3": "PER",
      "codenum": 604
    },
    {
      "name": "French Polynesia",
      "code": "PF",
      "code_3": "PYF",
      "codenum": 258
    },
    {
      "name": "Papua New Guinea",
      "code": "PG",
      "code_3": "PNG",
      "codenum": 598
    },
    {
      "name": "Philippines",
      "code": "PH",
      "code_3": "PHL",
      "codenum": 608
    },
    {
      "name": "Pakistan",
      "code": "PK",
      "code_3": "PAK",
      "codenum": 586
    },
    {
      "name": "Poland",
      "code": "PL",
      "code_3": "POL",
      "codenum": 616
    },
    {
      "name": "Saint Pierre and Miquelon",
      "code": "PM",
      "code_3": "SPM",
      "codenum": 666
    },
    {
      "name": "Pitcairn",
      "code": "PN",
      "code_3": "PCN",
      "codenum": 612
    },
    {
      "name": "Puerto Rico",
      "code": "PR",
      "code_3": "PRI",
      "codenum": 630
    },
    {
      "name": "Palestine, State of",
      "code": "PS",
      "code_3": "PSE",
      "codenum": 275
    },
    {
      "name": "Portugal",
      "code": "PT",
      "code_3": "PRT",
      "codenum": 620
    },
    {
      "name": "Palau",
      "code": "PW",
      "code_3": "PLW",
      "codenum": 585
    },
    {
      "name": "Paraguay",
      "code": "PY",
      "code_3": "PRY",
      "codenum": 600
    },
    {
      "name": "Qatar",
      "code": "QA",
      "code_3": "QAT",
      "codenum": 634
    },
    {
      "name": "Reunion",
      "code": "RE",
      "code_3": "REU",
      "codenum": 638
    },
    {
      "name": "Romania",
      "code": "RO",
      "code_3": "ROU",
      "codenum": 642
    },
    {
      "name": "Serbia",
      "code": "RS",
      "code_3": "SRB",
      "codenum": 688
    },
    {
      "name": "Russian Federation",
      "code": "RU",
      "code_3": "RUS",
      "codenum": 643
    },
    {
      "name": "Rwanda",
      "code": "RW",
      "code_3": "RWA",
      "codenum": 646
    },
    {
      "name": "Saudi Arabia",
      "code": "SA",
      "code_3": "SAU",
      "codenum": 682
    },
    {
      "name": "Solomon Islands",
      "code": "SB",
      "code_3": "SLB",
      "codenum": 90
    },
    {
      "name": "Seychelles",
      "code": "SC",
      "code_3": "SYC",
      "codenum": 690
    },
    {
      "name": "Sudan",
      "code": "SD",
      "code_3": "SDN",
      "codenum": 729
    },
    {
      "name": "Sweden",
      "code": "SE",
      "code_3": "SWE",
      "codenum": 752
    },
    {
      "name": "Singapore",
      "code": "SG",
      "code_3": "SGP",
      "codenum": 702
    },
    {
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "code": "SH",
      "code_3": "SHN",
      "codenum": 654
    },
    {
      "name": "Slovenia",
      "code": "SI",
      "code_3": "SVN",
      "codenum": 705
    },
    {
      "name": "Svalbard and Jan Mayen",
      "code": "SJ",
      "code_3": "SJM",
      "codenum": 744
    },
    {
      "name": "Slovakia",
      "code": "SK",
      "code_3": "SVK",
      "codenum": 703
    },
    {
      "name": "Sierra Leone",
      "code": "SL",
      "code_3": "SLE",
      "codenum": 694
    },
    {
      "name": "San Marino",
      "code": "SM",
      "code_3": "SMR",
      "codenum": 674
    },
    {
      "name": "Senegal",
      "code": "SN",
      "code_3": "SEN",
      "codenum": 686
    },
    {
      "name": "Somalia",
      "code": "SO",
      "code_3": "SOM",
      "codenum": 706
    },
    {
      "name": "Suriname",
      "code": "SR",
      "code_3": "SUR",
      "codenum": 740
    },
    {
      "name": "South Sudan",
      "code": "SS",
      "code_3": "SSD",
      "codenum": 728
    },
    {
      "name": "Sao Tome and Principe",
      "code": "ST",
      "code_3": "STP",
      "codenum": 678
    },
    {
      "name": "El Salvador",
      "code": "SV",
      "code_3": "SLV",
      "codenum": 222
    },
    {
      "name": "Sint Maarten (Dutch Part)",
      "code": "SX",
      "code_3": "SXM",
      "codenum": 534
    },
    {
      "name": "Syrian Arab Republic",
      "code": "SY",
      "code_3": "SYR",
      "codenum": 760
    },
    {
      "name": "Swaziland",
      "code": "SZ",
      "code_3": "SWZ",
      "codenum": 748
    },
    {
      "name": "Turks and Caicos Islands",
      "code": "TC",
      "code_3": "TCA",
      "codenum": 796
    },
    {
      "name": "Chad",
      "code": "TD",
      "code_3": "TCD",
      "codenum": 148
    },
    {
      "name": "French Southern Territories",
      "code": "TF",
      "code_3": "ATF",
      "codenum": 260
    },
    {
      "name": "Togo",
      "code": "TG",
      "code_3": "TGO",
      "codenum": 768
    },
    {
      "name": "Thailand",
      "code": "TH",
      "code_3": "THA",
      "codenum": 764
    },
    {
      "name": "Tajikistan",
      "code": "TJ",
      "code_3": "TJK",
      "codenum": 762
    },
    {
      "name": "Tokelau",
      "code": "TK",
      "code_3": "TKL",
      "codenum": 772
    },
    {
      "name": "Timor-Leste",
      "code": "TL",
      "code_3": "TLS",
      "codenum": 626
    },
    {
      "name": "Turkmenistan",
      "code": "TM",
      "code_3": "TKM",
      "codenum": 795
    },
    {
      "name": "Tunisia",
      "code": "TN",
      "code_3": "TUN",
      "codenum": 788
    },
    {
      "name": "Tonga",
      "code": "TO",
      "code_3": "TON",
      "codenum": 776
    },
    {
      "name": "Turkey",
      "code": "TR",
      "code_3": "TUR",
      "codenum": 792
    },
    {
      "name": "Trinidad and Tobago",
      "code": "TT",
      "code_3": "TTO",
      "codenum": 780
    },
    {
      "name": "Tuvalu",
      "code": "TV",
      "code_3": "TUV",
      "codenum": 798
    },
    {
      "name": "Taiwan, Province of China",
      "code": "TW",
      "code_3": "TWN",
      "codenum": 158
    },
    {
      "name": "Tanzania, United Republic of",
      "code": "TZ",
      "code_3": "TZA",
      "codenum": 834
    },
    {
      "name": "Ukraine",
      "code": "UA",
      "code_3": "UKR",
      "codenum": 804
    },
    {
      "name": "Uganda",
      "code": "UG",
      "code_3": "UGA",
      "codenum": 800
    },
    {
      "name": "United Arab Emirates",
      "code": "AE",
      "code_3": "ARE",
      "codenum": 784
    },
    {
      "name": "United Kingdom",
      "code": "GB",
      "code_3": "GBR",
      "codenum": 826
    },
    {
      "name": "United States",
      "code": "US",
      "code_3": "USA",
      "codenum": 840
    },
    {
      "name": "United States Minor Outlying Islands",
      "code": "UM",
      "code_3": "UMI",
      "codenum": 581
    },
    {
      "name": "Uruguay",
      "code": "UY",
      "code_3": "URY",
      "codenum": 858
    },
    {
      "name": "Uzbekistan",
      "code": "UZ",
      "code_3": "UZB",
      "codenum": 860
    },
    {
      "name": "Holy See",
      "code": "VA",
      "code_3": "VAT",
      "codenum": 336
    },
    {
      "name": "Saint Vincent and The Grenadines",
      "code": "VC",
      "code_3": "VCT",
      "codenum": 670
    },
    {
      "name": "Venezuela, Bolivarian Republic of",
      "code": "VE",
      "code_3": "VEN",
      "codenum": 862
    },
    {
      "name": "Virgin Islands, British",
      "code": "VG",
      "code_3": "VGB",
      "codenum": 92
    },
    {
      "name": "Virgin Islands, U.S.",
      "code": "VI",
      "code_3": "VIR",
      "codenum": 850
    },
    {
      "name": "Viet Nam",
      "code": "VN",
      "code_3": "VNM",
      "codenum": 704
    },
    {
      "name": "Vanuatu",
      "code": "VU",
      "code_3": "VUT",
      "codenum": 548
    },
    {
      "name": "Wallis and Futuna",
      "code": "WF",
      "code_3": "WLF",
      "codenum": 876
    },
    {
      "name": "Samoa",
      "code": "WS",
      "code_3": "WSM",
      "codenum": 882
    },
    {
      "name": "Yemen",
      "code": "YE",
      "code_3": "YEM",
      "codenum": 887
    },
    {
      "name": "Mayotte",
      "code": "YT",
      "code_3": "MYT",
      "codenum": 175
    },
    {
      "name": "South Africa",
      "code": "ZA",
      "code_3": "ZAF",
      "codenum": 710
    },
    {
      "name": "Zambia",
      "code": "ZM",
      "code_3": "ZMB",
      "codenum": 894
    },
    {
      "name": "Zimbabwe",
      "code": "ZW",
      "code_3": "ZWE",
      "codenum": 716
    }
  ]
}

export default countries