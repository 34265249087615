import React, { Component } from 'react';
import YearSelect from './YearSelect';
import MakeSelect from './MakeSelect';
import ModelSelect from './ModelSelect';
import TrimSelect from './TrimSelect';

class ShortFormVehicle extends Component {
    state = {  }
    render() { 

        let {questionId, response} = this.props.data;
        let year;
        let make;
        let model;
        let trim;

        if (typeof response === 'object'){
            year = !response.year ? "" : response.year;
            make = !response.make ? "" : response.make;
            model = !response.model ? "" : response.model;
            trim = !response.trim ? "" : response.trim;
        }

        return ( 
            <div>
                <h3 className="text-xlarge">
                    Vehicle Information
                </h3>
                <div className="mt-3">                                      
                    <h3 className="text-regular text-left">
                        Year
                    </h3>
                    <div className="mt-3">
                        <YearSelect key={year} questionId={questionId} handleChange={this.props.handleChange} defaultValue={year}/>                        
                    </div>
                </div>
                <div className="mt-3">                                      
                    <h3 className="text-regular text-left">
                        Make
                    </h3>
                    <div className="mt-3">
                        <MakeSelect key={year} questionId={questionId} handleChange={this.props.handleChange} defaultValue={make} year={year}/>                        
                    </div>
                </div>
                <div className="mt-3">                                      
                    <h3 className="text-regular text-left">
                        Model
                    </h3>
                    <div className="mt-3">
                        <ModelSelect key={`${year}-${make}`} questionId={questionId} handleChange={this.props.handleChange} defaultValue={model} year={year} make={make}/>                        
                    </div>
                </div>
                <div className="mt-3">                                      
                    <h3 className="text-regular text-left">
                        Trim
                    </h3>
                    <div className="mt-3">
                        <TrimSelect key={`${year}-${make}-${model}`} questionId={questionId} handleChange={this.props.handleChange} defaultValue={trim} year={year} make={make} model={model}/>                        
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ShortFormVehicle;