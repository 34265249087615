import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class MultipleChoice extends Component {

    state = { 
        submitting: false
    }

    saveAndContinue = async (questionId, value) => {

        this.setState({submitting:true})

        await this.props.handleChange(questionId, value);

        const nextStep = this.props.nextStep

        if (!this.props.lastQuestion){
            setTimeout(function(){                
                nextStep(false)                
            }, 150)
        }
    }

    getClasses = (selectedValue, optionValue) => {        

        let classes = 'btn btn-secondary btn-lg d-block w-100'

        if (selectedValue === optionValue){
            classes = 'btn btn-primary btn-lg d-block w-100'
        }

        return classes;
    }

    render() {

        let {questionId, question, options, response} = this.props.data;

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>
                <div className="mt-3">
                    {   !this.state.submitting ?
                            options.map(option => {
                                return <div key={option.value} className="m-2"><button className={this.getClasses(response, option.value)} name={option.value} value={option.value} onClick={() => {this.saveAndContinue(questionId,option.value)}}>{option.value}</button></div>
                            })
                        :
                            options.map(option => {
                                return <div key={option.value} className="m-2"><button className={this.getClasses(response, option.value)} name={option.value} value={option.value} disabled>{option.value}</button></div>
                            })
                    }
                    {this.state.submitting &&
                        <div style={{position:'absolute', top:'50%', left:'48.5%'}}><FontAwesomeIcon icon="spinner" spin size="2x" className="text-muted" /></div>
                    }
                    
                </div>
            </div>
        );        
    }
}
 
export default MultipleChoice;