import React, { Component } from 'react';

class MakeSelect extends Component {

    state = { 
        dataLoaded: false,
        year: this.props.year,
        makes: []
    }

    componentDidMount(){
        // Fetch the years
        fetch(`${process.env.REACT_APP_API_URL}/vehicleInfo/getMakes?year=${this.state.year}`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {

                        this.setState({
                            ...this.state,
                            dataLoaded: true,
                            makes: result.makes
                        })
                    } else if (result["success"] === false) { 
                        console.log("Error fetching vehicle makes.")
                    } else {
                        console.log("Error fetching vehicle makes.")
                    }
                }).catch(error => {
                    console.log("Error fetching vehicle makes.")                                      
                });  
    }

    render() { 
        return ( 
            this.state.dataLoaded ? 
                <select value={this.props.defaultValue} className="form-control" onChange={event => this.props.handleChange(this.props.questionId, event.target.value, false, true, 'make')}>
                    <option></option>
                    {this.state.makes.map(make => {
                        return <option value={make}>{make}</option>
                    })}
                </select>
            :
                <select className="form-control">
                    <option> - </option>
                </select>
        );
    }
}
 
export default MakeSelect;