import React, { Component } from 'react';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class MultipleChoiceMulti extends Component {

    state = {  }

    saveAndContinue = (questionId, value) => {
        this.props.handleChange(questionId, value, true);
    }

    getClasses = (selectedValues, optionValue) => {

        let classes = 'btn btn-secondary btn-lg d-block w-100'

        if (selectedValues.includes(optionValue)){
            classes = 'btn btn-primary btn-lg d-block w-100'
        }

        return classes;
    }

    render() {

        let {questionId, question, options, response} = this.props.data;

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>
                <div className="alert alert-info">You may select more than one.</div>
                <div className="mt-3">
                    {
                        options.map(option => {
                            return <div key={option.value} className="m-2"><button className={this.getClasses(response, option.value)} name={option.value} value={option.value} onClick={() => {this.saveAndContinue(questionId,option.value)}}>{option.value}</button></div>
                        })
                    }
                </div>                
            </div>
        );        
    }
}
 
export default MultipleChoiceMulti;