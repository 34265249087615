import React, { Component } from 'react';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class Redirect extends Component {
    
    state = { 
        submitting: false
    }

    saveAndContinue = async (questionId, value) => {
        
        await this.props.handleChange(questionId, value);

        const nextStep = this.props.nextStep

        if (!this.props.lastQuestion){
            setTimeout(function(){
                nextStep(false)
            }, 150)
        }
    }
    
    render() { 

        let { questionId, question, placeholder, disclaimer, callToAction, negCallToAction } = this.props.data;

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>
                <div className="mt-3">
                    <a className="btn btn-primary btn-lg" onClick={() => {this.saveAndContinue(questionId,"Clicked")}} href={`${mapSurveyResponses(placeholder, this.props.fullSurvey)}`} target="_blank">{callToAction !== '' ? callToAction : 'Go Here!'}</a>                    
                </div>               
                <div className="mt-3">
                    <button className="btn btn-lnk text-secondary" onClick={() => {this.saveAndContinue(questionId,"No Thanks")}}><u>{negCallToAction !== '' ? negCallToAction : 'No Thanks!'}</u></button>
                </div>
                <div className="mt-5">
                    <p dangerouslySetInnerHTML={{ __html: mapSurveyResponses(disclaimer, this.props.fullSurvey) }} className="text-info text-small text-center"></p>
                </div>
            </div>
        );
    }
}
 
export default Redirect;