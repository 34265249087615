import React, { Component } from 'react';
import CallToAction from '../templates/CallToAction/CallToAction';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class DateSelector extends Component {
    
    state = { 
        year: '',
        month: '',
        day: ''
    }
    
    componentDidMount = () => {
        let { response } = this.props.data;

        let year = ''
        let month = ''
        let day = ''

        try {
            year = response.split("/")[0];
            month = response.split("/")[1];
            day = response.split("/")[2];

            this.setState({
                year: year,
                month: month,
                day: day
            })

        } catch(err){}        
    }

    handleChangeYear = (e) =>{
        this.setState({
            ...this.state,
            year: e.target.value
        }, function(){
            this.checkAllFields('y')
        })
    }

    handleChangeMonth = (e) =>{
        this.setState({
            ...this.state,
            month: e.target.value
        }, function(){
            this.checkAllFields('m')
        })        
    }

    handleChangeDay = (e) =>{
        this.setState({
            ...this.state,
            day: e.target.value
        }, function(){
            this.checkAllFields('d')
        }) 
    }    

    checkAllFields = (field) => {
        let date = '';

        if (this.state.year.length === 4 && !isNaN(this.state.year) && field === 'y'){
            this.month.focus()
        }

        if (this.state.month.length === 2 && !isNaN(this.state.month) && field === 'm'){
            this.day.focus()
        }

        if (this.state.year.length === 4 && (this.state.month.length === 2 || this.state.month.length === 1) && (this.state.day.length === 2 || this.state.day.length === 1) &&
            !isNaN(this.state.year) && !isNaN(this.state.month) && !isNaN(this.state.day)){            
            date = `${this.state.year}/${this.state.month}/${this.state.day}`
        }
     
        this.props.handleChange(this.props.data.questionId, date)
    }

    render() { 

        let {question, response, callToAction} = this.props.data;
        let year = ''
        let month = ''
        let day = ''

        try {
            year = response.split("/")[0];
            month = response.split("/")[1];
            day = response.split("/")[2];
        } catch(err){}

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>                
                <div className="mt-3 d-flex m-auto" style={{maxWidth: '250px'}}>
                    <div className="text-center mx-2">
                        YYYY
                        <input ref={(input) => { this.year = input; }} className="form-control text-center" type="text" defaultValue={year} onChange={(e)=>this.handleChangeYear(e)}/>
                    </div>
                    <div className="text-center mx-2">
                        MM
                        <input ref={(input) => { this.month = input; }} className="form-control text-center" type="text" defaultValue={month} onChange={(e)=>this.handleChangeMonth(e)}/>
                    </div>
                    <div className="text-center mx-2">
                        DD
                        <input ref={(input) => { this.day = input; }} className="form-control text-center" type="text" defaultValue={day} onChange={(e)=>this.handleChangeDay(e)}/>
                    </div>
                </div>
                { callToAction !== '' &&
                    <CallToAction lastQuestion={this.props.lastQuestion} nextStep={this.props.nextStep} callToAction={callToAction} getNextButton={this.props.getNextButton} />
                }
            </div>
        );
    }
}
 
export default DateSelector;