import React, { Component } from 'react';
import IndexLayout1 from './IndexLayout1/IndexLayout1';
import IndexLayout2 from './IndexLayout2/IndexLayout2';

import './Index.css';
import IndexLayout3 from './IndexLayout3/IndexLayout3';

class Index extends Component {

    state = {
        mode: '',
        iframe: '',
        page: 1
    }

    getHeaderStyles = () => {
        let design = this.props.design;
        let style = {color: design.main.headerText.color}

        return style;
    }

    getHeaderClasses = () => {
        let design = this.props.design;
        let classes = ''

        if (design.main.headerText.withBackground === 'Y'){
            classes = 'd-inline-block rounded p-3 with-background'
        }

        return classes;
    }

    getDescriptionStyles = () => {
        let design = this.props.design;
        let style = {color: design.main.descriptionText.color}
        
        return style;
    }

    getDescriptionClasses = () => {
        let design = this.props.design;
        let classes = 'mt-3'

        if (design.main.descriptionText.withBackground === 'Y'){
            classes = 'mt-3 d-inline-block rounded p-3 with-background'
        }

        return classes;
    }

    loadIframe = (url) => {
        this.setState({
            mode: 'iframe',
            iframe: `${url}`,
            page: this.state.page
        })
    }

    changePage = (page) => {
        this.setState({
            ...this.state,
            page: page
        })
    }

    getIndexLayout = (layoutId) => {

        let layout;

        if (this.state.mode !== 'iframe'){
            switch(layoutId) {
            case 1:
                layout = <IndexLayout1 layout={this.props.layout} design={this.props.design} content={this.props.content} getHeaderClasses={this.getHeaderClasses} getHeaderStyles={this.getHeaderStyles} getDescriptionClasses={this.getDescriptionClasses} getDescriptionStyles={this.getDescriptionStyles} propertyId={this.props.propertyId} siteId={this.props.siteId} surveyId={this.props.surveyId} survey={this.props.survey} avtc={this.props.avtc} mbi={this.props.mbi} pushnamiOverride={this.props.pushnamiOverride} loadIframe={this.loadIframe} changePage={this.changePage} page={this.state.page}></IndexLayout1>
                break;
            case 2:
                layout = <IndexLayout2 layout={this.props.layout} design={this.props.design} content={this.props.content} getHeaderClasses={this.getHeaderClasses} getHeaderStyles={this.getHeaderStyles} getDescriptionClasses={this.getDescriptionClasses} getDescriptionStyles={this.getDescriptionStyles} propertyId={this.props.propertyId} siteId={this.props.siteId} surveyId={this.props.surveyId} survey={this.props.survey} avtc={this.props.avtc} mbi={this.props.mbi} pushnamiOverride={this.props.pushnamiOverride} loadIframe={this.loadIframe} changePage={this.changePage} page={this.state.page}></IndexLayout2>
                break;
            case 3:
                layout = <IndexLayout3 layout={this.props.layout} design={this.props.design} content={this.props.content} getHeaderClasses={this.getHeaderClasses} getHeaderStyles={this.getHeaderStyles} getDescriptionClasses={this.getDescriptionClasses} getDescriptionStyles={this.getDescriptionStyles} propertyId={this.props.propertyId} siteId={this.props.siteId} surveyId={this.props.surveyId} survey={this.props.survey} avtc={this.props.avtc} mbi={this.props.mbi} pushnamiOverride={this.props.pushnamiOverride} loadIframe={this.loadIframe} changePage={this.changePage} page={this.state.page}></IndexLayout3>
                break;
            default:
                layout = <IndexLayout1 layout={this.props.layout} design={this.props.design} content={this.props.content} getHeaderClasses={this.getHeaderClasses} getHeaderStyles={this.getHeaderStyles} getDescriptionClasses={this.getDescriptionClasses} getDescriptionStyles={this.getDescriptionStyles} propertyId={this.props.propertyId} siteId={this.props.siteId} surveyId={this.props.surveyId} survey={this.props.survey} avtc={this.props.avtc} mbi={this.props.mbi} pushnamiOverride={this.props.pushnamiOverride} loadIframe={this.loadIframe} changePage={this.changePage} page={this.state.page}></IndexLayout1>
            } 
        } else {
            layout = <div style={{width: '100%', height:'100%', paddingTop: '60px', backgroundColor:'RGBA(0,0,0,0.5)'}}><iframe title='Result' style={{ width: '100%', height:'100%', minHeight:'600px' }} src={this.state.iframe}></iframe></div>   
        }

        return layout;
    }

    render() {
        return (    
            <React.Fragment>
                { this.getIndexLayout(this.props.layout) }                
            </React.Fragment>
        );
    }

}
 
export default Index;